<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Area Guides</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Area Guides"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.areaGuideDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Area Guide</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Area Guides</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="area_guides"
        class="mt-4"
        no-data-text="No Area Guides found"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.areaGuideDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDeleteAreaGuide(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <area-guide-dialog ref="areaGuideDialog" />

    <v-dialog v-model="deleteAreaGuide.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Area</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deleteAreaGuide.area_guide.title }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteAreaGuide"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteAreaGuide.loading"
            @click="saveDeleteAreaGuide"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AreaGuideDialog from "./components/AreaGuideDialog.vue";

export default {
  components: {
    AreaGuideDialog,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Area Guides",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Region Name", value: "name" },
        { text: "Slug", value: "slug" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteAreaGuide: {
        dialog: false,
        area_guide: {},
        loading: false,
      },
    };
  },

  computed: {
    area_guides() {
      let area_guides = this.$store.getters["melrose/areaGuidesStore/all"];

      if (this.searchTerm !== "") {
        area_guides = area_guides.filter((a) => {
          const name = a.name.toLowerCase();
          const slug = a.slug.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm) || slug.includes(searchTerm);
        });
      }

      return area_guides;
    },
  },
  methods: {
    openDeleteAreaGuide(area_guide) {
      this.deleteAreaGuide.area_guide = area_guide;
      this.deleteAreaGuide.dialog = true;
    },

    resetDeleteAreaGuide() {
      this.deleteAreaGuide.dialog = false;
      this.deleteAreaGuide.area_guide = {};
      this.deleteAreaGuide.loading = false;
    },

    saveDeleteAreaGuide() {
      this.deleteAreaGuide.loading = true;

      this.$store
        .dispatch("melrose/areaGuidesStore/deleteAreaGuide", {
          appId: this.$route.params.id,
          areaGuideId: this.deleteAreaGuide.area_guide.id,
        })
        .then(() => {
          this.resetDeleteAreaGuide();
        })
        .catch(() => {
          this.deleteAreaGuide.loading = false;
        });
    },
  },
};
</script>
