<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Area Guide
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form
          @submit.prevent="save"
          method="post"
          id="area-guide-form"
          enctype="multipart/form-data"
        >
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-text-field
            label="Slug *"
            v-model="slug"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('slug')"
            :error-messages="errors['slug']"
          ></v-text-field>

          <v-textarea
            label="One Sentence Summary"
            v-model="fields.one_sentence_summary"
            rows="2"
            outlined
            :error="errors.hasOwnProperty('one_sentence_summary')"
            :error-messages="errors['one_sentence_summary']"
          ></v-textarea>

          <v-textarea
            label="Content *"
            v-model="fields.content"
            rows="6"
            outlined
            :error="errors.hasOwnProperty('content')"
            :error-messages="errors['content']"
          ></v-textarea>

          <v-textarea
            label="Nearby Areas"
            v-model="fields.nearby_areas"
            rows="4"
            outlined
            :error="errors.hasOwnProperty('nearby_areas')"
            :error-messages="errors['nearby_areas']"
          ></v-textarea>

          <v-text-field
            label="Lat"
            v-model="fields.lat"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('lat')"
            :error-messages="errors['lat']"
          ></v-text-field>
          <v-text-field
            label="Long"
            v-model="fields.long"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('long')"
            :error-messages="errors['long']"
          ></v-text-field>

          <v-file-input
            label="Hero Left"
            v-model="fields.hero_left"
            accept="image/png, image/jpeg"
            placeholder="Choose a Image"
            outlined
            @change="heroLeftHasChanged()"
            :error="errors.hasOwnProperty('hero_left')"
            :error-messages="errors['hero_left']"
          ></v-file-input>

          <v-file-input
            label="Hero Right"
            v-model="fields.hero_right"
            accept="image/png, image/jpeg"
            placeholder="Choose a Image"
            outlined
            @change="heroRightHasChanged()"
            :error="errors.hasOwnProperty('hero_right')"
            :error-messages="errors['hero_right']"
          ></v-file-input>

          <v-file-input
            label="List Thumbnail"
            v-model="fields.list_thumnail"
            accept="image/png, image/jpeg"
            placeholder="Choose a Image"
            outlined
            @change="listThumnailHasChanged()"
            :error="errors.hasOwnProperty('list_thumnail')"
            :error-messages="errors['list_thumnail']"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="area-guide-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      area_guide: {},
      fields: {
        name: null,
        slug: null,
        content: null,
        one_sentence_summary: null,
        nearby_areas: null,
        hero_left: null,
        hero_right: null,
        list_thumnail: null,
        hero_right_has_changed: false,
        list_thumnail_has_changed: false,
        hero_left_has_changed: false,
        lat: null,
        long: null,
      },
      errors: {},
    };
  },

  computed: {
    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.name;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    open(area_guide = null) {
      if (area_guide !== null) {
        this.area_guide = area_guide;
        this.fields.name = area_guide.name;
        this.fields.slug = area_guide.slug;
        this.fields.content = area_guide.content;
        this.fields.one_sentence_summary = area_guide.one_sentence_summary;
        this.fields.nearby_areas = area_guide.nearby_areas;

        this.fields.lat = area_guide.lat;
        this.fields.long = area_guide.long;

        this.fields.hero_left = area_guide.hero_left
          ? new File([area_guide.hero_left], area_guide.hero_left)
          : null;
        this.fields.hero_right = area_guide.hero_right
          ? new File([area_guide.hero_right], area_guide.hero_right)
          : null;
        this.fields.list_thumnail = area_guide.list_thumnail
          ? new File([area_guide.list_thumnail], area_guide.list_thumnail)
          : null;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.areaGuideId = this.area_guide.id;
      }

      this.$store
        .dispatch("melrose/areaGuidesStore/saveAreaGuide", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.area_guide = {};
      this.fields = {
        name: null,
        slug: null,
        content: null,
        one_sentence_summar: null,
        nearby_areas: null,
        hero_left: null,
        hero_right: null,
        list_thumnail: null,
        hero_right_has_changed: false,
        list_thumnail_has_changed: false,
        hero_left_has_changed: false,
        lat: null,
        long: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },

    heroRightHasChanged() {
      this.fields.hero_right_has_changed = true;
    },

    heroLeftHasChanged() {
      this.fields.hero_left_has_changed = true;
    },

    listThumnailHasChanged() {
      this.fields.list_thumnail_has_changed = true;
    },
  },
};
</script>
